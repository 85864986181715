import React from "react"
import styled from "@emotion/styled"
import Template from "../../templates/about"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const style = { maxWidth: "400px" }

const Page = props => {
  return (
    <Template {...props} options={options}>
      <MediaContainer>
        <h2>Media</h2>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ maxWidth: "400px" }}>
            <a
              href="https://tv.cuny.edu/show/buildingny/PR1008596"
              target="_blank"
            >
              <Img style={style} fluid={props.images[2]} />
            </a>
            <p>
              Rutter discusses his roots and lessons in development on The
              Stoler Report.
            </p>
            <small>CUNY TV</small>
          </div>
          <div style={{ maxWidth: "400px" }}>
            <a
              href="https://www.youtube.com/watch?v=OXQo0QzDQD8"
              target="_blank"
            >
              <Img style={style} fluid={props.images[1]} />
            </a>
            <p>
              Rutter discusses New York City's emerging neighborhoods on The
              Stoler Report.
            </p>
            <small>CUNY TV</small>
          </div>
        </div>
      </MediaContainer>
    </Template>
  )
}
{
  /* <div style={{display: "flex"}}>
<div style={{flexBasis: "40%", marginRight: "2rem"}}>
    <iframe width="480" height="315" src="https://www.youtube.com/embed/OXQo0QzDQD8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>Rutter discusses New York City's emerging neighborhoods on The Stoler Report.</p>
    <small>CUNY TV</small>
</div>
<div style={{flexBasis: "40%"}}>
    <iframe width="480" height="315" src="https://www.youtube.com/embed/u2C_hddQnqI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>Rutter discusses his roots and lessons in development on The Stoler Report.</p>
    <small>CUNY TV</small>
</div>
</div>  */
}

const MediaContainer = styled.div`
  margin-top: -5rem;
  h2 {
    font: 400 ${props => props.theme.fontSize.header}
      ${props => props.theme.fonts.serif};
  }
  h3 {
    font: 400 ${props => props.theme.fontSize.subheader}
      ${props => props.theme.fonts.serif};
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fontSize.paragraph};
    &:hover {
      text-decoration: underline;
    }
  }
`

const options = {
  title: "Mitchell B. Rutter",
  href: "about/mitchell-b-rutter",
  id: "mitchell-b-rutter",
  body: [
    {
      text:
        "Mitchell B. Rutter is founding partner and chief executive officer of Essex Capital Partners Ltd., a New York-based real estate investment and development company with office, residential, industrial and retail projects along the East Coast. Rutter established Essex Capital in 1991, and under his leadership the company has acquired, repositioned and developed more than five million square feet of real estate with a value of more than $3 billion.\n\nRutter’s expertise encompasses all aspects of the investment cycle, from market selection, financial analysis and construction to asset management, leasing and disposition. He has completed a series of transformative projects that include ground-up construction, asset repositioning, public/private partnerships, and large-scale master planning and revitalization initiatives.\n\nRutter began his career as a real estate attorney at the law firm of Simpson Thacher & Bartlett.\n\nA Phi Beta Kappa graduate of New York University and New York University School of Law, Rutter serves on numerous boards, including Atlantic Bank, New York University Schack Institute of Real Estate, the Furman Center for Real Estate and Urban Policy at New York University School of Law, and the Walsh School of Foreign Service at Georgetown University. He is an emeritus board member of the New York City Business Relocation Assistance Corporation and the Times Square Business Improvement District.\n\nRutter is a native New Yorker who graduated from Benjamin N. Cardozo High School in Queens.",
    },
  ],
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      imageThree: file(relativePath: { eq: "banners/mitchell.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageStoler: file(relativePath: { eq: "rutter/stoler_400.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageScreen: file(relativePath: { eq: "rutter/screen_400.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageLinkedin: file(relativePath: { eq: "rutter/rutter_400.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const imageThree = data.imageThree.childImageSharp.fluid
  const imageStoler = data.imageStoler.childImageSharp.fluid
  const imageScreen = data.imageScreen.childImageSharp.fluid
  const imageLinkedin = data.imageLinkedin.childImageSharp.fluid
  const images = [imageLinkedin, imageStoler, imageScreen, imageThree]
  return <Page {...props} images={images} />
}
